import React from 'react'
import ConfidenceHigh from 'src/images/wtn/confidence-high.svg'
import ConfidenceMid from 'src/images/wtn/confidence-mid.svg'
import ConfidenceLow from 'src/images/wtn/confidence-low.svg'
import { PersonData_personById_worldTennisNumbers } from 'src/graphql-types/ita/PersonData'
import { CommonWorldTennisNumberTypeEnum } from 'src/graphql-types/ita/globalITATypes'

const CONFIDENCE_THRESHOLDS = {
  high: { min: 70, max: Number.POSITIVE_INFINITY },
  mid: { min: 40, max: 70 },
  low: { min: Number.NEGATIVE_INFINITY, max: 40 }
} as const

type ConfidenceLevel = keyof typeof CONFIDENCE_THRESHOLDS

const CONFIDENCE_ICONS: Record<ConfidenceLevel, React.FC<React.SVGProps<SVGSVGElement>>> = {
  low: ConfidenceLow,
  mid: ConfidenceMid,
  high: ConfidenceHigh
}

export const getConfidenceLevel = (confidence: number | undefined): ConfidenceLevel | undefined => {
  if (typeof confidence === 'number') {
    for (const key in CONFIDENCE_THRESHOLDS) {
      const threshold = CONFIDENCE_THRESHOLDS[key]
      if (confidence >= threshold.min && confidence < threshold.max) return key as ConfidenceLevel
    }
  }

  return undefined
}

export const getConfidenceIcon = (level: ConfidenceLevel) => CONFIDENCE_ICONS[level]
export const getWtnValues = (worldTennisNumbers: PersonData_personById_worldTennisNumbers[]) => {
  const wtnValues: {
    ratingSingles?: number
    ratingDoubles?: number
    confidenceSingles?: number
    confidenceDoubles?: number
  } = {}
  worldTennisNumbers.forEach(({ type, tennisNumber, confidence }) => {
    const typeKey = type === CommonWorldTennisNumberTypeEnum.DOUBLE ? 'Doubles' : 'Singles'
    wtnValues[`rating${typeKey}`] = tennisNumber ?? undefined
    wtnValues[`confidence${typeKey}`] = confidence ?? undefined
  })

  return wtnValues
}
