import graphql from 'graphql-tag'

export const SEARCH_PLAYERS = graphql`
  query SearchPlayers(
    $limit: Int!
    $skip: Int!
    $filter: PersonFilterOptionsInput
    $sort: SortOrder
  ) {
    players: searchPeople(pageArgs: { limit: $limit, skip: $skip }, filter: $filter, sort: $sort) {
      results {
        id: personId
        standardFamilyName
        standardGivenName
        age
        nationalityCode
        birthDate
        sex
        class
        groups {
          id: groupId
          groupName
          seasonId
          status
          role
        }
      }
      total
    }
    currentSeason: getCurrentSeason {
      id
    }
  }
`

export const SEARCH_PLAYERS_DOWNLOAD = graphql`
  query SearchPlayersDownload(
    $limit: Int!
    $skip: Int!
    $filter: PersonFilterOptionsInput
    $sort: SortOrder
  ) {
    players: searchPeople(pageArgs: { limit: $limit, skip: $skip }, filter: $filter, sort: $sort) {
      results {
        id: personId
        standardFamilyName
        standardGivenName
        age
        nationalityCode
        birthDate
        sex
        class
        groups {
          id: groupId
          groupName
          seasonId
          status
          role
        }
        email
      }
      total
    }
  }
`

export const GET_PLAYER_TOURNAMENTS = graphql`
  query Td_personTournaments(
    $tdPersonId: td_PersonIDInput!
    $tournamentFilter: td_TournamentFilterOptions
    $tournamentSort: td_SortOrder
    $matchUpsSort: td_SortOrder
    $matchUpsFilter: td_MatchUpFilterOptions
  ) {
    td_person(id: $tdPersonId) {
      externalID
      id
      tournaments(filter: $tournamentFilter, sort: $tournamentSort) {
        totalItems
        items {
          end
          id
          name
          season
          start
          level
          matchUps(sort: $matchUpsSort, filter: $matchUpsFilter) {
            totalItems
            items {
              id
              wtnId
              start
              end
              winningSide
              type
              roundNumber
              roundName
              roundPosition
              sides {
                sideNumber
                players {
                  playerNumber
                  person {
                    id
                    externalID
                    nativeFamilyName
                    nativeGivenName
                    standardFamilyName
                    standardGivenName
                  }
                }
              }
              score {
                scoreString
              }
              worldTennisNumbers {
                confidence
                personId
                tennisNumber
                type
              }
              extensions {
              name
              value
              }
            }
          }
        }
      }
    }
  }
`
export const GET_DUAL_MATCHES_PAGINATED = graphql`
  query DualMatchesPaginated(
    $skip: Int!
    $limit: Int!
    $filter: desk_DualMatchesFilter
    $sort: desk_DualMatchesSort
    $teamId: String
  ) {
    dualMatchesPaginated(skip: $skip, limit: $limit, filter: $filter, sort: $sort) {
      totalItems
      items {
        id
        location
        isConferenceMatch
        isNeutralLocation
        scoringFormat
        contextualInfo(teamId: $teamId) {
          didWin
          homeTeam
          result
          thisTeam {
            abbreviation
            didWin
            name
            logo {
              url
              width
            }
            id
            score
            sideNumber
          }
          opponent {
            name
            logo {
              url
              width
            }
            id
            sideNumber
            abbreviation
            didWin
          }
        }
        startDateTime {
          dateTimeString
          noScheduledTime
        }
        teams {
          abbreviation
          didWin
          name
          logo {
            url
            width
          }
          id
          score
          sideNumber
          division
        }
      }
    }
  }
`

export const SEARCH_PLAYER_BY_ID = graphql`
  query SearchPlayerById($id: String!) {
    player: personByPersonId(personId: $id) {
      id: personId
      standardFamilyName
      standardGivenName
      age
      nationalityCode
      birthDate
      sex
      class
      groups {
        id: groupId
        groupName
        seasonId
        status
        role
      }
      status
    }
    matchUps: td_matchUps(filter: { personIds: [{ type: ExternalID, identifier: $id }] }) {
      totalItems
    }
    currentSeason: getCurrentSeason {
      id
    }
  }
`

export const MERGE_PLAYERS = graphql`
  mutation MergePlayers($movingRecordId: String!, $destinationRecordId: String!) {
    mergeSurvivor: mergePerson(
      loserUniqueId: $movingRecordId
      uniqueIdType: personId
      winnerUniqueId: $destinationRecordId
    ) {
      id: personId
      standardFamilyName
      standardGivenName
      age
      nationalityCode
      birthDate
      sex
      class
      groups {
        id: groupId
        groupName
        seasonId
        status
        role
      }
    }
  }
`

export const GET_BOX_SCORE = graphql`
query DualMatch($dualMatchId: ID!) {
  dualMatch(id: $dualMatchId) {
    id
    scoringFormat
    singlesOrderFinish
    doublesOrderFinish
    startDateTime {
      dateTimeString
      noScheduledTime
      timezoneName
    }

    homeTeam {
      id
      score
      didWin
      logo {
        url
      }
    }

    teams {
      id
      abbreviation
      conference
      division
      name
      sideNumber
      score
      parentOrganisation {
        name
      }
      logo {
        url
      }
    }

    tieMatchUps {
      id
      type
      collectionId
      collectionPosition
      status
      side1 {
        didWin
        participants {
          firstName
          lastName
          personId
        }
        score {
          scoreString
          sets {
            setScore
            tiebreakSet
            tiebreakScore
            didWin
          }
        }
        teamAbbreviation
      }
      side2 {
        didWin
        participants {
          firstName
          lastName
          personId
        }
        score {
          scoreString
          sets {
            didWin
            setScore
            tiebreakScore
            tiebreakSet
          }
        }
      }
    }

  }
}
`
export const GET_ITA_PERSON_BY_ID = graphql`
query PersonData($uniqueId: String!, $uniqueIdType: UniqueIdType!) {
  personById(uniqueId: $uniqueId, uniqueIdType: $uniqueIdType) {
    class
    age
    standardFamilyName
    standardGivenName
    worldTennisNumbers {
      confidence
      tennisNumber
      type
    }
    birthDate
    category
    externalId
    groups {
      groupId
      groupName
      groupType
      role
      status
      seasonId
      seasonName
    }
    status
    tennisId
    userId
    sex
    personId
    nationalityCode
  }
}
`

export const GET_TEAMS = graphql`
query FindGroup {
  findGroup {
    results {
      groupId
      groupFullName
      groupName
    }
  }
}
`

export const GET_SEASONS = graphql`
query ListSeasons {
  listSeasons {
    startDate
    endDate
    id
    name
  }
}
`