// extracted by mini-css-extract-plugin
export var age = "players-module--age--cost3";
export var bodyBase = "players-module--body-base--rVWDs players-module--site-font--0o0EX";
export var bodyLarge = "players-module--body-large--fd7yY players-module--body-base--rVWDs players-module--site-font--0o0EX";
export var bodyLargeBold = "players-module--body-large-bold--om6cy players-module--body-base--rVWDs players-module--site-font--0o0EX";
export var bodyRegular = "players-module--body-regular--5Qp1F players-module--body-base--rVWDs players-module--site-font--0o0EX";
export var bodyRegularBold = "players-module--body-regular-bold--jyJ78 players-module--body-base--rVWDs players-module--site-font--0o0EX";
export var bodySmall = "players-module--body-small--QfWLR players-module--body-base--rVWDs players-module--site-font--0o0EX";
export var bodySmallBold = "players-module--body-small-bold--4-XZN players-module--body-base--rVWDs players-module--site-font--0o0EX";
export var borderTop = "players-module--border-top--JILTz";
export var breakWordContainer = "players-module--break-word-container--802LD";
export var buttonIconBase = "players-module--button-icon-base--n3iKO";
export var clickLink = "players-module--click-link--S3MBp";
export var dropdownBase = "players-module--dropdown-base--PSd89";
export var dropdownSelectBase = "players-module--dropdown-select-base--x6PW3 players-module--text-input--C0BsS";
export var flexCol = "players-module--flex-col--tHnUI";
export var formErrorMessage = "players-module--form-error-message--CHjtM";
export var gender = "players-module--gender--Z-KY9";
export var h3 = "players-module--h3--TSpVP";
export var h4 = "players-module--h4--BWBkY";
export var hoverLink = "players-module--hover-link--U3gS1";
export var hoverRow = "players-module--hover-row--V4DTG";
export var membershipContainer = "players-module--membership-container--P4bBL players-module--flex-col--tHnUI players-module--primary-border--K6bER";
export var membershipHeader = "players-module--membership-header--Cv0s6";
export var membershipHeading = "players-module--membership-heading--gNnha";
export var membershipLabel = "players-module--membership-label--gcPA2";
export var moreFiltersBorderClass = "players-module--more-filters-border-class--o+inf";
export var pageBg = "players-module--page-bg--4GCmt";
export var personId = "players-module--personId--5nLUl";
export var pointer = "players-module--pointer--7W6c3";
export var primaryBorder = "players-module--primary-border--K6bER";
export var shadowBoxLight = "players-module--shadow-box-light--fTe45";
export var siteFont = "players-module--site-font--0o0EX";
export var slideDownAndFade = "players-module--slideDownAndFade--xeZTR";
export var slideLeftAndFade = "players-module--slideLeftAndFade--GorIl";
export var slideRightAndFade = "players-module--slideRightAndFade--F1-V0";
export var slideUpAndFade = "players-module--slideUpAndFade--sl+X+";
export var statusDecoration = "players-module--status-decoration--kXrC7";
export var stdName = "players-module--stdName--bw6SQ";
export var tableCell = "players-module--tableCell--SIc1Q";
export var tennisId = "players-module--tennisId--rAB5V";
export var textInput = "players-module--text-input--C0BsS";
export var textInverted = "players-module--text-inverted--23ZMw";
export var textMediumDark = "players-module--text-medium-dark--wBKzI";
export var tooltipFont = "players-module--tooltipFont--XbNA6";
export var unstyledButton = "players-module--unstyled-button--0+hdZ";
export var wtnRating = "players-module--wtnRating--v1+PT";