import React from 'react'
import { PageMaxWidth } from '../util-components/util-components'
import PageHeader from 'src/components/page-header/page-header'
import Panel from 'src/components/panel/panel'
import {
  initQueryFilterTableHook,
  QFTFiltersConfig,
  QFTTableConfig,
  QFTConfig
} from 'src/hooks/use-query-filter-table'
import { withNotifications } from '../notification-container/notification-container'
import { DocumentNode } from 'apollo-link'
import { Datum } from 'src/components/admin-table/admin-table'
import { TFunction, useTranslation } from 'react-i18next'
import { ValueOrGetValue } from 'src/types'

type ConfigHelpers = { t: TFunction }

export interface Props<T, U extends Datum> {
  gqlQuery: DocumentNode
  filtersConfig: ValueOrGetValue<QFTFiltersConfig<T, U>, [ConfigHelpers]>
  tableConfig: ValueOrGetValue<QFTTableConfig<T, U>, [ConfigHelpers]>
  config: ValueOrGetValue<QFTConfig<T, U>, [ConfigHelpers]>
}

const Players = <T, U extends Datum>({
  gqlQuery,
  filtersConfig,
  tableConfig,
  config
}: Props<T, U>) => {
  const { t } = useTranslation()
  const useQFT = initQueryFilterTableHook<T, U>(gqlQuery)

  const {
    components: { FilterBar, AdminTable, AccessoryBar, props }
  } = useQFT(
    typeof filtersConfig === 'function' ? filtersConfig({ t }) : filtersConfig,
    typeof tableConfig === 'function' ? tableConfig({ t }) : tableConfig,
    typeof config === 'function' ? config({ t }) : config
  )

  return (
    <PageMaxWidth>
      <PageHeader title={t('players')} noPadding />
      <Panel>
        <FilterBar {...props.filterBar} withClearBtn />
        <AccessoryBar {...props.accessoryBar} />
        <AdminTable {...props.adminTable} />
      </Panel>
    </PageMaxWidth>
  )
}

export default withNotifications(Players)
